<template>
  <div id="app">
    <div v-show="loaded || needToken" class="container-fluid" :class="{ 'px-0': width < $root.widthMobil, 'mx-2': width > 768 }">
      <div v-if="width >= $root.widthMobil && false">
        <div style="text-align: center; margin: 20px 0">
          <a v-if="lang == 'ru'" class="btn btn-primary" href="https://joldo.kg/ru/pdd.html">Вернуться к выбору</a>
          <a v-else class="btn btn-primary" href="https://joldo.kg/ru/onlayn-test-2023-kyrgyzcha.html">Тандоого кайтуу</a>
        </div>
      </div>
      <div class="container-fluid" :class="{ 'px-2': width < $root.widthMobil }">
        <div class="row pt-lg-15" ref="view_block">
          <div class="col-xl-3 col-lg-3 col-md-3 px-0">
            <!-- Тест ПДД -->
            <div v-if="showAD" class="p-2" ref="ad_block">
              <insert-ad v-if="width >= $root.widthMobil" :type="width < 768 ? 'ad' : 'ad-v'" :class="width < 768 ? '' : ''" :id="ad1()" />
            </div>
          </div>
          <div class="col-xl-6 col-lg-9 col-md-8 px-2">
            <div
              :class="{ 'pt-2': width < 768 && width >= $root.widthMobil, 'px-2': width < 768 && width >= $root.widthMobil }"
              :style="width >= $root.widthMobil ? 'min-height: 600px' : ''"
            >
              <router-view v-if="!needToken && !disabled" @showAD="refillAD()" @adOff="adOff()" @changeAD="changeAD()" />
              <span v-else-if="disabled">Страница не найдена</span>
              <span v-else>Данная страница устарела, пожалуйста вернитесь на страницу выбора</span>
            </div>
            <div v-if="width < $root.widthMobil && false" style="text-align: center; margin: 20px 0">
              <a v-if="lang == 'ru'" class="btn btn-primary" href="https://joldo.kg/ru/pdd.html">Вернуться к выбору</a>
              <a v-else class="btn btn-primary" href="https://joldo.kg/ru/onlayn-test-2023-kyrgyzcha.html">Тандоого кайтуу</a>
            </div>
          </div>
          <div class="col-xl-3 col-lg-12 col-md-12 px-0">
            <!-- Тест ПДД2 -->
            <insert-ad v-if="showAD" :type="width < 768 ? 'ad2' : 'ad2-v'" class="mt-2 p-2" :id="ad2()" style="height: auto !important" ref="ad_block2" />
          </div>
        </div>
      </div>
    </div>
    <Content />
    <Footer />
  </div>
</template>

<script>
export default {
  components: {
    insertAd: () => import("@/components/Ads"),
    Content: () => import("@/components/Content"),
    Footer: () => import("@/components/Footer"),
  },
  data() {
    return {
      needUpgdade: false,
      versionInterval: null,
      actualVersion: null,
      lang: this.$route.meta.lang,
      authData: this.$root.authData,
      needToken: false,
      showAD: false,
      canRefreshAD: true,
      adsIdList: ["6384643272", "1379860508", "9594537302", "3570935188", "6005526834", "2059914485"],
      adsIdList_: ["3176494925", "9493661306", "2049451403", "2928252957", "9736369731"],
      adsId: 0,
      adsId2: 0,
      disabled: false,
    };
  },
  created() {
    this.$root.width = window.innerWidth;
    if (this.$route.meta?.disabled) this.disabled = true;
    this.needToken = !true;
    const token = this.$cookies.get("tokenTest") || null;
    if (!token) {
      this.setNeedToken();
    }
    const t = ("PDD test page" + new Date().date).md5();
    if (token != t) {
      //console.log("token compare", token, t, this.auth);
      this.setNeedToken();
    }
    if (token && false) {
      //    const token_now = JSON.parse(localStorage.getItem("tokenTest1") || null);
      if (token_now == token) this.needToken = true;
      else localStorage.setItem("tokenTest", JSON.stringify(token));
    }
  },
  computed: {
    auth() {
      const res = this.authData || {};
      return res;
    },
    loaded() {
      return this.$root.loaded;
    },
    width() {
      return this.$root.width;
    },
    showAD1: {
      get() {
        console.log("read showAD", this.$root.showAD);
        return this.$root.showAD;
      },
      set(v) {
        console.log("set showAD", v);

        this.$root.showAD = v;
      },
    },
  },
  methods: {
    ad1() {
      let res = this.adsIdList[this.adsId];
      return res;
    },
    ad2() {
      let res = this.adsIdList[this.adsId2];
      return res;
    },
    getAds() {
      //return "6005526834";
      // Получаем текущее значение по индексу
      const res = this.adsIdList[this.adsId];
      // Увеличиваем индекс для следующего вызова
      return res;
    },
    changeAD() {
      if (this.canRefreshAD) {
        this.canRefreshAD = false;
        this.adOff();
        Vue.nextTick(() => {
          this.refillAD();
        });
      }
    },
    adOff() {
      console.log("adOff");
      this.showAD = false;
    },
    refillAD() {
      this.$root.width = window.innerWidth;
      this.canRefreshAD = false;
      this.adsId = Math.floor(Math.random() * this.adsIdList.length);
      this.adsId2 = (this.adsId + 1) % this.adsIdList.length;

      this.showAD = true;
      console.log("refill sss", this.ad1(), this.ad2());
      setTimeout(() => {
        //console.log('refresh ad');
        (adsbygoogle = window.adsbygoogle || []).push({});
      }, 500);
      setTimeout(() => {
        //console.log('refresh ad2');
        (adsbygoogle = window.adsbygoogle || []).push({});
      }, 1500);
      setTimeout(() => {
        this.canRefreshAD = true;
      }, 22000);
    },
    setNeedToken() {
      //if (this.auth?.id == 36) return;
      // this.needToken = true;
      this.changeAD();
      return;
      this.showAD = true;
      setTimeout(() => {
        //console.log('refresh ad');
        (adsbygoogle = window.adsbygoogle || []).push({});
        setTimeout(() => {
          //console.log('refresh ad2');
          (adsbygoogle = window.adsbygoogle || []).push({});
        }, 500);
      }, 500);
    },
  },
};
</script>
